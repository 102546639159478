import { useCallback, useMemo } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { loadSlim } from "tsparticles-slim";

export default function Particle() {
  const options = useMemo(() => {
    return {}
  }, [])

  const particleInit = useCallback((engine) => {
    loadSlim(engine);
    loadFull(engine);
  }, [])
  return (
    <Particles
      init={particleInit} options={options} />
  )
}