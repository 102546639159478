import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import Particle from "../Particles";
import pdf from '../../Assets/Resume/General Abhishek Resume.pdf'
import download from "../../Assets/Resume/download-96.png";
import 'react-pdf/dist/Page/TextLayer.css';
import '../../styles/resume.css'
import throttle from 'lodash/throttle';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


export default function Resume() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [initialWidth, setInitialWidth] = useState(null);

  const pdfWrapper = useRef(null);

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setInitialWidth(pdfWrapper.current.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 3000));
    setPdfSize();
    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 3000));
    };
  }, []);
  

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const calculateHeight = () => {
    // Example: Adjust height based on device width
    return windowWidth > 786 ? 1200 : 600;
  };

  return (
    <div className="main">
      <div className="resume-section">
        {/* <Particle /> */}
        <div className="res-btn">
          <a href={pdf} target="_blank" rel="noopener noreferrer"><img src={download} alt="" />
            {' '}Download CV
          </a>
        </div>


        <div className="resume-file">
          <Document file={pdf} className="res-file">
            <Page pageNumber={1} size='A4' width={initialWidth} height={calculateHeight()/1.15} className='page-pdf' />
            {/* <Page pageNumber={1} size='A4'  scale={width > 786 ? 1.7 : 0.6}/> */}
          </Document>
        </div>

        <div className="res-btn">
          <a href={pdf} target="_blank" rel="noopener noreferrer"><img src={download} alt="" />
            {' '}Download CV
          </a>
        </div>
      </div>
    </div>
  );
}

